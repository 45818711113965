<template>
  <v-card class="py-5">
    <v-card-title class="justify-center text-h4 pb-10 primary--text">
      {{ isUpdate ? 'แก้ไข' : 'สร้าง' }}รายชื่อผู้จำหน่าย
    </v-card-title>
    <v-card-text class="popup-background">
      <v-row justify="center">
        <v-col cols="6" class="mt-2">
          <v-text-field
            label="ชื่อผู้จำหน่าย"
            name="name"
            outlined
            dense
            background-color="white"
            :error-messages="$v.form.name.$error ? $error_text : ''"
            v-model="form.name"
          />
          <v-text-field
            label="ผู้ติดต่อ"
            name="contact_people"
            outlined
            dense
            background-color="white"
            v-model="form.contact_people"
          />
          <v-text-field
            label="เบอร์ติดต่อ"
            name="contact_tel"
            outlined
            dense
            background-color="white"
            v-model="form.contact_tel"
          />
          <v-text-field
            label="เลขผู้เสียภาษี"
            name="taxpayer_number"
            outlined
            dense
            background-color="white"
            v-model="form.taxpayer_number"
          />
          <v-text-field
            label="สาขา"
            name="branch_office"
            outlined
            dense
            background-color="white"
            v-model="form.branch_office"
          />
          <v-text-field
            label="ที่อยู่"
            name=""
            outlined
            dense
            autocomplete="new-password"
            background-color="white"
            @click="
              (dialogAddressData = form.location),
                (dialogAddress = true),
                (dialogAddressKey = 'location')
            "
            :value="
              form.location.address
                ? `${form.location.address} ${form.location.sub_district} ${form.location.district} ${form.location.province} ${form.location.postal_code}`
                : ''
            "
          />
          <v-textarea
            label="หมายเหตุ"
            name="note"
            outlined
            dense
            background-color="white"
            v-model="form.note"
          ></v-textarea>
        </v-col>
      </v-row>

      <v-dialog persistent scrollable max-width="1200" v-model="dialogAddress">
        <AddressDetail
          :item="dialogAddressData"
          @onClose="dialogAddress = false"
          @onSubmit="onSubmitAddress"
        />
      </v-dialog>
    </v-card-text>
    <v-card-actions class="py-5">
      <v-spacer />
      <v-btn outlined class="px-5 mr-10" color="grey" @click="close()">
        ปิด
      </v-btn>
      <v-btn
        class="px-10 mr-15"
        color="primary"
        @click="save()"
        :loading="loading"
        :disabled="loading"
      >
        บันทึกข้อมูล
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import AddressDetail from '@/components/Address/AddressDetail.vue';
import {
  required,
  maxLength,
  minLength,
  numeric,
  decimal,
  minValue
} from 'vuelidate/lib/validators';
export default {
  components: {
    AddressDetail
  },
  props: {
    id: {
      type: String,
      default: null
    }
  },
  validations: {
    form: {
      name: { required }
      // contact_people: { required },
      // contact_tel: { required },
      // taxpayer_number: { required },
      // branch_office: { required },
      // location: {
      //   address: { required },
      //   district: { required },
      //   postal_code: { required },
      //   province: { required },
      //   sub_district: { required }
      // }
    }
  },
  watch: {
    id: function(newVal, oldVal) {
      this.getData(newVal);
    }
  },
  mounted() {
    this.getData(this.id);
  },

  computed: {
    isUpdate() {
      return this.id;
    }
  },
  data() {
    return {
      loading: false,
      form: {
        dealer_id: 0,
        name: '',
        contact_people: '',
        contact_tel: '',
        taxpayer_number: '',
        branch_office: '',
        location: {
          address: '',
          building: '',
          district: '',
          postal_code: '',
          province: '',
          sub_district: ''
        },
        note: ''
      },
      /***ที่อยู่****/
      dialogAddress: false,
      dialogAddressData: {
        address: '',
        building: '',
        district: '',
        province: '',
        sub_district: '',
        postal_code: ''
      }
    };
  },
  methods: {
    save() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        this.$alertServerError({ title: 'กรุณากรอกข้อมูลให้ครบถ้วน' });
        return;
      }
      this.loading = true;
      this.$alertConfirm({ title: `ยืนยันบันทึกข้อมูล ?`, text: `` }).then(
        async result => {
          if (result.isConfirmed) {
            let subPath = 'add_dealer';
            if (this.isUpdate) {
              subPath = 'edit_dealer';
              this.form = { dealer_id: this.form._id, ...this.form };
              delete this.form._id;
            }
            let body = {
              token: this.$jwt.sign(this.form, this.$privateKey, {
                noTimestamp: true
              })
            };
            await this.$axios
              .post(`${this.$baseUrl}/dealer/${subPath}`, body)
              .then(res => {
                this.$alertSuccess({ title: res.status });
              })
              .catch(err => {
                console.log('err', err);
                this.$alertServerError({ title: err.error_message });
              });
            this.$emit('onSave');
          }
          this.loading = false;
          this.$v.form.$reset();
          this.resetForm();
        }
      );
    },
    close() {
      this.$v.form.$reset();
      this.resetForm();
      this.$emit('onClose');
    },

    async getData(id) {
      if (id) {
        let body = {
          token: this.$jwt.sign({ dealer_id: id }, this.$privateKey, {
            noTimestamp: true
          })
        };
        await this.$axios
          .post(`${this.$baseUrl}/dealer/get_by_id`, body)
          .then(async res => {
            this.form = { ...res.result };
          })
          .catch(err => {
            console.log('err', err);
          });
      } else {
        this.form = {
          dealer_id: 0,
          name: '',
          contact_people: '',
          contact_tel: '',
          taxpayer_number: '',
          branch_office: '',
          location: {
            address: '',
            building: '',
            district: '',
            postal_code: '',
            province: '',
            sub_district: ''
          },
          note: ''
        };
      }
      this.loading = false;
    },
    onSubmitAddress(val) {
      this.form[this.dialogAddressKey] = val;
      this.dialogAddress = false;
    },
    resetForm() {
      this.form = {
        dealer_id: 0,
        name: '',
        contact_people: '',
        contact_tel: '',
        taxpayer_number: '',
        branch_office: '',
        location: {
          address: '',
          building: '',
          district: '',
          postal_code: '',
          province: '',
          sub_district: ''
        },
        note: ''
      };
    }
  }
};
</script>
